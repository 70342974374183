.leaflet-tooltip-left:before {
    @apply hidden;
}

.leaflet-tooltip {
    background-color: rgba(44, 50, 50, 0.8) !important;
    color: #fff !important;
    border: none !important;
    border-radius: 2px !important;
}

.leaflet-control-expand {
    background-image: url("../images/icons/ic-expand.svg");
    background-repeat: no-repeat;
    background-size: 1rem;
}