.inactive {
  @apply hidden;
}

.faq-category.active {
  @apply bg-accent;
}

.ac {
  @apply border-border-secondary rounded-lg bg-background-quaternary p-4;
  & .ac-header {
    & .ac-trigger {
      @apply font-text font-normal text-text-grey-600 text-body-sm;
      &:after {
        content: '';
        height: 15px;
        background-image: url('../images/icons/ic-chevron-down.svg');
        background-repeat: no-repeat;
        background-position: center;
        transform-origin: center;
        transform: rotate(0deg);
        top: 0.6rem;
      }
    }
  }
  & .ac-panel {
    & .ac-text {
      @apply text-body-md text-text-grey-500 font-text font-normal;
      ul {
        @apply list-disc pl-4;
      }
      ol {
        @apply list-decimal pl-4;
      }

      a {
        @apply font-medium underline hover:no-underline;
      }
    }
  }
}
.ac.is-active {
  & .ac-header {
    & .ac-trigger {
      &:after {
        content: '' !important;
        transform: rotate(180deg);
      }
    }
  }
}
