.main-container {
  @apply flex w-full mx-auto px-[1rem] lg:px-[3rem];

  & .narrow {
    @apply sm:w-[80%] mx-auto;
  }

  & .narrowest {
    @apply sm:w-[70%] mx-auto;
  }
}
