.input,
textarea,
select {
  @apply px-8pt;
  @apply h-fields;
  @apply rounded-button;
  @apply border-border border;
  @apply bg-transparent;

  &.light {
    @apply bg-background-quaternary border-0 text-text-body;
    &.bordered {
      @apply border border-border-secondary;
    }
  }
}
.input:not(select),
textarea {
  @apply py-8pt;
}
textarea {
  @apply h-32;
}
select {
  @apply bg-transparent appearance-none relative py-0;

  background-image: url('../images/icons/ic-chevron-down.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 0.75rem;
  background-position: right 0.5rem center;
  padding-right: 1.8rem !important;
}

.input-label {
  @apply mini-label text-text-grey-600 font-medium mb-1 block;
}

.search {
  &:focus {
    outline: none;
  }
}

input[type='radio'] {
  @apply w-4 h-4 border border-border-quaternary pr-3 m-0 bg-transparent rounded-full grid place-content-center appearance-none translate-y-px cursor-pointer;

  &::before {
    content: '';
    box-shadow: inset 1rem 1rem theme('colors.border.quaternary');
    @apply w-2 h-2 rounded-full scale-0 transition-all duration-200 ease-in-out ml-3;
  }

  &:checked::before {
    @apply scale-100;
  }

  &:checked {
    @apply bg-accent;
  }
}

input[type='checkbox'] {
  @apply w-4 h-4 border border-border-quaternary pr-3 m-0 bg-transparent grid place-content-center appearance-none translate-y-px rounded-sm cursor-pointer;

  &::before {
    content: '';
    @apply w-[0.625rem] h-[0.625rem] scale-0 transition-all duration-200 ease-in-out ml-3;
    background-image: url('../images/icons/ic-check.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &:checked::before {
    @apply scale-100;
  }

  &:checked {
    @apply bg-accent;
  }

  &.dark {
    @apply border-border-tertiary bg-background-darker-yellow;

    &::before {
      background-image: url('../images/icons/ic-check-white.svg');
    }

    &:checked {
      @apply bg-background-black;
    }
  }
}

.challenges__filter {
  & .challenges__filter-item {
    @apply mr-2 rounded-button hover:border-transparent hover:bg-background-secondary hover:text-text-body-inverted h-[34px] px-2 py-1 border border-border bg-white transition-colors duration-300 ease-in-out cursor-pointer;

    &.active {
      @apply border-transparent  bg-background-secondary text-text-body-inverted;
    }
  }

  & input[type='radio'] {
    @apply hidden;
  }

  & select {
    @apply bg-white h-[34px] px-2 pr-8;
  }
}
