@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-2 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-3 {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.podium {
  opacity: 1;
}
.podium-1 {
  animation: fade-in 0.4s ease-in;
}
.podium-2 {
  animation: fade-in-2 0.8s ease-in;
}
.podium-3 {
  animation: fade-in-3 1.2s ease-in;
}

[data-react-class='Contests/Segments'] {
  @apply h-full;
}

.segment__position {
  @apply relative;
  @screen 2xl {
    &:before,
    &:after {
      content: '';
      @apply w-px h-5 bg-background-grey-200 absolute bottom-1/2 translate-y-1/2;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}
