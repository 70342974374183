#main-menu-frontpage {
  .logo-frontpage {
    @apply block;
  }
  .logo-frontpage-inverted {
    @apply hidden;
  }

  .sign-up-button {
    @apply md:bg-secondary text-text-body-inverted transition-all duration-300 ease-in-out;
  }

  .sign-in-button {
    @apply bg-transparent md:bg-background border-secondary transition-all duration-300 ease-in-out text-text-body-inverted md:text-text;
  }

  .header-link {
    @apply  transition-all duration-300 ease-in-out text-text-body-inverted md:text-text;
  }

  &.over-dark {
    .logo-frontpage {
      @apply hidden;
    }

    .logo-frontpage-inverted {
      @apply block;
    }

    .sign-up-button {
      @apply md:bg-primary text-text;
    }

    .sign-in-button {
      @apply bg-transparent text-text-body-inverted border-text-body-inverted;
    }

    .header-link {
      @apply text-text-body-inverted;
    }
  }
}
