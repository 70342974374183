.avatar__text {
  @apply w-full h-full flex justify-center items-center rounded-full shrink-0;

  &.avatar__text_theme_pink {
    @apply bg-pink text-purple;
  }
  &.avatar__text_theme_green {
    @apply bg-background-darker-yellow text-text-body;
  }
  &.avatar__text_theme_blue {
    @apply bg-background-tertiary text-text-grey-600;
  }

  &.avatar__text-small {
    @apply text-body-xxs font-normal tracking-[-0.08rem];
  }
}

.avatar__image {
  @apply w-full h-full rounded-full object-cover;
}

.avatar__image-default {
  @apply w-6;
}
