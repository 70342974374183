.button {
  @apply bg-primary text-body-sm text-text-body h-[2.5rem] lg:h-[2.75rem] px-3 inline-flex justify-center items-center appearance-none cursor-pointer rounded transition-all duration-300 leading-none;
}
.button.no-hover {
  @apply cursor-default;
}
.button:not(.no-hover):hover {
  @apply bg-accent-darker;
}
.button.light {
  @apply bg-white text-text-body;
}
.button.light:hover {
  @apply bg-white text-text-body;
}
.button.dark {
  @apply bg-secondary text-text-heading-inverted transition-all duration-300;
}
.button.dark:not(.no-hover):hover {
  @apply bg-primary text-text-body;
}
.button.dark.light-text {
  @apply text-text-body-inverted;
}
.button.dark.light-text:not(.no-hover):hover {
  @apply text-text-body;
}
.button.bordered {
  @apply border bg-white border-secondary text-text-body;
}
.button.bordered-light {
  @apply border bg-transparent border-white text-text-body-inverted;
}
.button.bordered-light:hover {
  @apply bg-background text-text-body;
}
.button.bordered.transparent {
  @apply bg-transparent;
}
.button.bordered:not(.no-hover):hover {
  @apply bg-background-black text-text-heading-inverted;
}
.button.small {
  @apply py-2 px-3 h-[2.75rem] text-body-sm leading-none;
}

.side-menu-link {
  @apply transition-all duration-300;

  & .icon {
    @apply transition-all duration-300;
  }

  &:hover {
    @apply text-text-accent;

    & .icon {
      @apply bg-primary;
    }
  }
}
