#map {
  min-height: 11.25rem;
  @apply w-full mb-16pt z-0 aspect-[3/4] sm:aspect-square md:aspect-[5/4] lg:aspect-[5/3];
}

.map {
  @apply z-0;
}

.leaflet-container {
  & .show-position-control {
    @apply flex items-center bg-secondary text-text-heading-inverted h-[2.5rem] lg:h-[2.75rem] px-3 rounded;

    & a,
    & p,
    & span {
      @apply text-text-body-inverted font-semibold;
    }
  }

  & .map-position-marker {
    width: 4rem !important;
    height: 4rem !important;

    &.has-orientation {
      & .map-position-orientation {
        @apply absolute inset-0 z-0;
        background-image: url(../images/icons/ic-position-direction.svg);
        background-size: 4rem;
        background-position-x: center;
        background-position-y: top;
        background-repeat: no-repeat;
      }
    }

    & .map-position-marker-inner {
      width: 2.5rem !important;
      height: 2.5rem !important;
      margin: 0.75rem;
      border-width: 0.375rem;
      @apply rounded-full bg-accent-secondary relative border-white z-10;
    }
  }
}
