/* Font families */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/roboto/Roboto-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/roboto/Roboto-Italic.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/roboto/Roboto-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/roboto/Roboto-MediumItalic.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/roboto/Roboto-Bold.ttf') format('ttf');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/roboto/Roboto-BoldItalic.ttf') format('ttf');
}

/* Default styles */
body {
  @apply text-text;
}
h1.large {
  @apply text-h1mob;
}
h1 {
  @apply text-h1mob;
  @apply leading-none;
  @apply mb-8;
  @apply font-heading;
}
h2 {
  @apply text-h2mob;
  @apply leading-none;
  @apply mb-6;
  @apply font-heading;
}
h3 {
  @apply text-h3mob;
  @apply leading-none;
  @apply mb-12pt;
  @apply font-heading;
}
h4 {
  @apply text-h4mob;
  @apply leading-none;
  @apply mb-8pt;
  @apply font-heading;
}
h5 {
  @apply text-h5mob;
  @apply leading-none;
  @apply mb-8pt;
  @apply font-heading;
}

p {
  @apply mb-12pt;
}

@media (min-width: 769px) {
  h1.large {
    @apply text-h1-large;
  }
  h1 {
    @apply text-h1;
  }
  h2 {
    @apply text-h2;
  }
  h3 {
    @apply text-h3;
  }
  h4 {
    @apply text-h4;
  }
  h5 {
    @apply text-h5;
  }
  h6 {
    @apply text-h5;
  }
}

/* ==================== */
/* Specific typography classes */
.fluid-crazy-text {
  @apply text-heading-lg sm:text-fluid-heading-2xl;
}
.crazy-text {
  @apply text-heading-md lg:text-heading-xl font-heading leading-none lg:leading-none;
}
.huge-text {
  @apply text-heading-lg lg:text-heading-xl font-heading leading-heading;
}
.fluid-big-heading {
  @apply text-heading-sm sm:text-fluid-heading-lg;
}
.big-heading {
  @apply text-heading-md lg:text-heading-lg font-heading leading-heading;
}
.hero-headings {
  @apply text-heading-sm font-medium lg:text-heading-xl font-heading;
}
.medium-heading {
  @apply text-heading-sm font-medium lg:text-heading-lg font-heading leading-heading;
}

.heading {
  @apply text-heading-xs sm:text-heading-md font-heading leading-heading;
}
.small-heading {
  @apply text-heading-xs lg:text-heading-sm font-heading leading-heading;
}
.mini-heading {
  @apply text-heading-xs font-heading leading-heading;
}
.big-body-text {
  @apply text-body-lg font-text;
}
.body-text {
  @apply text-body-md;
}
.small-body-text {
  @apply text-body-sm font-text;
}
.mini-text {
  @apply text-body-xs;
}
.xmini-text {
  @apply text-body-xxs;
}
.big-label {
  @apply text-heading-xs lg:text-heading-sm;
}
.normal-label {
  @apply text-body-md;
}
.small-label {
  @apply text-body-sm;
}
.mini-label {
  @apply text-body-xs font-text;
}
.xmini-label {
  @apply text-body-xxs;
}
.mini-label-bold {
  @apply text-body-xs font-text font-medium;
}
.button-text {
  @apply text-body-sm;
}
.link-text {
  @apply text-body-sm;
}
.small-link-text {
  @apply text-body-xs;
}
p:last-child {
  @apply mb-0;
}

/* ==================== */
/* Wysiwyg */
.wysiwyg {
  & h1 > * {
    @apply font-heading text-h2;
  }
  & h2 > * {
    @apply font-heading text-h3;
  }
  & h3 > * {
    @apply font-heading text-h4;
  }
  & h4 > * {
    @apply font-heading text-h5;
  }
  & h5 > * {
    @apply font-heading text-h5;
  }
  & p {
    @apply text-general;
  }
  & a {
    @apply font-medium underline hover:no-underline;
  }
  & img {
    @apply w-full;
  }
  ul {
    @apply list-disc pl-4;
  }
  ol {
    @apply list-decimal pl-4;
  }
}
