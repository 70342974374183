/* Kaminari default pagination */
.pagination {
  @apply flex justify-center items-center;
  & .prev,
  & .next {
    @apply inline-block w-8 h-8 rounded bg-background-secondary relative appearance-none;
    &:after {
      content: '';
      @apply absolute inset-0;
      background: url('../images/icons/ic-chevron-left.svg') no-repeat center
        center;
    }
  }
  & .prev {
    @apply mr-6;
  }
  & .next {
    @apply ml-6;
    &:after {
      @apply transform rotate-180;
    }
  }

  & .page {
    @apply mx-2 px-[2px] text-body-sm appearance-none;
    &.current {
      @apply font-medium relative;
      &:after {
        content: '';
        @apply absolute bottom-[-0.1rem] left-0 right-0 w-full min-w-[0.8rem] h-px bg-text-body;
      }
    }
  }
}
