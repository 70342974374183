.tippy-box[data-theme~='hurryup'] {
  @apply bg-background-secondary font-heading font-normal text-text-body-inverted bg-opacity-80 rounded-lg p-4 min-w-[20rem];
}
.tippy-box[data-theme~='hurryup-auto'] {
  @apply bg-background-secondary font-heading font-normal text-text-body-inverted bg-opacity-80 rounded-lg p-4;
}
.tippy-box[data-theme~='hurryup'][data-placement^='top'] > .tippy-arrow::before,
.tippy-box[data-theme~='hurryup'][data-placement^='bottom']
  > .tippy-arrow::before,
.tippy-box[data-theme~='hurryup'][data-placement^='left']
  > .tippy-arrow::before,
.tippy-box[data-theme~='hurryup'][data-placement^='right']
  > .tippy-arrow::before {
  @apply hidden;
}
.tippy-box[data-theme~='hurryup'][data-show-arrow='true'] > .tippy-arrow {
  @apply block;
}

.swal2-container {
  @apply p-4;
}
.swal2-actions button.swal2-confirm {
  @apply bg-primary text-text-body;
  &:hover {
    background-image: none;
  }
  &:focus {
    box-shadow: none;
  }
}
