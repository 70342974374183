.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.2s;
  opacity: 0;
  display: none;
}
.overlay.open {
  @apply flex justify-center items-center opacity-100;
}
.search-icon {
  background-image: url('images/icons/ic-search.svg');
  @apply bg-no-repeat bg-[left_1rem_center];
  background-size: 1rem;

  &:focus {
    background-image: url('images/icons/ic-search-white.svg');
  }
}
