@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.text-reveal-onScroll {
  @apply opacity-0;
  & * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  & .highlighted {
    @apply italic font-semibold;
  }
}
.fadeIn {
  @apply opacity-0;
}
