.tab {
  @apply px-0 md:px-3 pb-4 mb-6 mr-4 md:mr-2 relative text-text duration-300 button-text opacity-60 hover:opacity-100;
  transition: all 0.2s;

  &.tab-active {
    @apply opacity-100 border-b border-secondary;
  }

  &.active {
    @apply text-primary;
    @apply opacity-100;
  }
}

.tab:after,
.profile-tab:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 0.25rem;
  height: 100%;
  left: -1.25rem;
  -webkit-transform: translateX(-50%) translateY(-25%);
  -moz-transform: translateX(-50%) translateY(-25%);
  transform: translateX(-50%) translateY(-25%);
  @apply bg-primary;
  transition: all 0.6s;
  opacity: 0;
}
.profile-tab:after {
  height: 90%;
  top: 0.2rem;
}
.tab.active:after,
.profile-tab.active:after {
  opacity: 1;
}
