@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './components/generic-animations.scss';
@import './components/typography.scss';
@import './components/leaflet.scss';
@import './components/contests.scss';
@import './components/local-menu.scss';
@import './components/map.scss';
@import './components/tippy.scss';
@import './components/range-slider.scss';
@import './components/button.scss';
@import './components/pinned-slider.scss';
@import './components/container.scss';
@import './components/form.scss';
@import './components/front-page-menu.scss';
@import './components/sticky-top-menu.scss';
@import './components/pagination.scss';
@import './components/faq.scss';
@import './components/notification.scss';
@import './components/profile-picture.scss';
@import './components/tabs.scss';
@import './components/menus.scss';
@import './components/leaderboards.scss';
@import './components/search.scss';

body,
* {
  @apply font-text;
}

body {
  @apply bg-background text-text-body font-heading;
}

body.noscroll {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

input,
select,
textarea,
button {
  @apply cursor-pointer;
}

.shadow {
  -webkit-box-shadow: 0px 2px 20px 0px rgba(103, 103, 103, 0.1);
  -moz-box-shadow: 0px 2px 20px 0px rgba(103, 103, 103, 0.1);
  box-shadow: 0px 2px 20px 0px rgba(103, 103, 103, 0.1);
}

.svg-fill {
  & svg path {
    @apply fill-current;
  }
}

.force-white-icon {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2978%)
    hue-rotate(173deg) brightness(109%) contrast(105%);
}
