#main-menu {
  /* -webkit-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1); */

  transition: all 0.2s;

  /* -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  transform: translateX(0%); */

  visibility: visible;
  opacity: 1;
  z-index: 30;
}

#main-menu.closed {
  /* -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  transform: translateX(100%); */

  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

#main-menu.button,
#main-menu.a {
  @apply font-heading;
}

.menu__item {
  @apply flex small-link-text py-3 border-b border-background-black w-full text-left relative opacity-100 my-4pt hover:opacity-80;
}
.menu__item.active {
  @apply text-text;
  @apply opacity-100;
}

.menu-link {
  @apply relative;
  transition: all 0.2s;
  @apply opacity-100;
  @apply my-4pt;
}
.menu-link:hover {
  @apply opacity-80;
}
.menu-link.active {
  @apply text-text;
  @apply opacity-100;
}

.menu__item-appinstall {
  @apply md:hidden;
}
@media all and (display-mode: standalone) {
  .menu__item-appinstall {
    @apply hidden; /* hide the install button */
  }
}

#side-navigation {
  animation: fadeIn 0.3s;
  @screen md {
    animation: none;
  }
}
