#sectionPin {
  overflow: hidden;
  display: flex;
  left: 0;

  @apply py-20 md:pb-0 md:pt-[4rem] md:min-h-screen;
}

.pin-wrap {
  @apply flex items-start md:items-center justify-start md:px-[5vw];

  & .pin-item {
    @apply first:px-0 px-[10vw] md:px-[5vw] min-w-[90vw];
  }
}

.swiper-pagination {
  top: 28rem !important;

  @apply md:hidden;

  @screen sm {
    top: 31rem !important;
  }
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

.swiper-pagination-bullet {
  background-color: theme('colors.background.quinary') !important;
  opacity: 0.2 !important;
}

.swiper-pagination {
  height: max-content !important;
  pointer-events: none !important;
}