th {
  @apply font-text mb-4 font-normal text-text-grey-600;
}

th,
td {
  @apply text-left p-2 relative text-body-sm whitespace-nowrap;
}

td {
  @apply text-text-body;
}

tbody td {
  @apply first:rounded-l last:rounded-r;
}
tbody tr {
  &:nth-child(even) {
    & td {
      @apply bg-background-grey-200;
    }
  }
}
tbody tr.current-user td {
  @apply bg-background-grey-300;
}

.rc-table table {
  @apply min-w-full border-spacing-y-2 border-separate;

  & tbody {
    & td {
      @apply small-body-text;
    }
  }
}
table tr th.leaderboard__row-item-highlight {
  @apply bg-primary font-semibold rounded;
}
tbody tr td.leaderboard__row-item-highlight {
  @apply font-semibold;
}
