.notification {
  @apply bg-background-tertiary border;

  &.style-information {
    @apply border-accent-secondary text-accent-secondary;
  }

  &.style-warning {
    @apply border-warning-border text-warning-text bg-warning;
  }
}
